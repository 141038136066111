function saverecord(){
    Metro.dialog.create({
        title: "Database Save",
        content: "<div>The record has been saved</div>",
        autoHide: 3000
    });
}

function saverecordfailed(){
    Metro.dialog.create({
        title: "Database Save",
        content: "<div>The record has not been saved successfully.</div>",
        autoHide: 10000
    });
}

function noRecordsFound(){
    Metro.dialog.create({
        title: "Movement Details",
        content: "<div>There are no movement records found for the selected date.</div>",
        autoHide: 10000
    });
}



function customsDemo(){
    Metro.dialog.create({
        title: "Customs demo",
        content: "<div>This dialog with customs classes</div>",
        clsDialog: "bg-dark",
        clsTitle: "fg-yellow",
        clsContent: "fg-white",
        clsDefaultAction: "alert"
    });
}

function animateDemo(){
    Metro.dialog.create({
        title: "Animation demo",
        content: "<div>This dialog animated with onShow, onHide events</div>",
        onShow: function(el){
            el.addClass("ani-swoopInTop");
            setTimeout(function(){
                el.removeClass("ani-swoopInTop");
            }, 500);
        },
        onHide: function(el){
            el.addClass("ani-swoopOutTop");
            setTimeout(function(){
                el.removeClass("ani-swoopOutTop");
            }, 500);
        }
    });
}

function actionsDemo(){
    Metro.dialog.create({
        title: "Dialog actions",
        content: "<div>This dialog with custom actions</div>",
        actions: [
            {
                caption: "Yes, i'am",
                cls: "js-dialog-close alert",
                onclick: function(){
                    alert("You choose YES");
                }
            },
            {
                caption: "No, thanks",
                cls: "js-dialog-close",
                onclick: function(){
                    alert("You choose NO");
                }
            }
        ]
    });
}
