//Probably not required any more
/*
function myFunction(sel, day, el)
{
var d = new Date(sel[0]);
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

  window.location.replace("/daily-loads/" + [year, month, day].join(''));
}
 */

/*
function deletevariety(year, varietycode, variety)
{
  var r=confirm("Are you sure you want to delete the variety " + variety)
  if (r==true)
    window.location = "/index.php/mainweb/deleteCommitment/" + year +"/" + varietycode;
  else return false;
}

 */

function addCommitment (year)
{
  window.location = "/index.php/mainweb/addCommitment/" + year ;
}

function saveMemberAdditionalInfo (year)
{
  var r=document.getElementById('memberlease').value;
  window.location = "/index.php/mainweb/saveAdditionalCommitment/" + year +"/" + r;
}

function saveMemberHarvestAcceptance (year)
{
   window.location = "/index.php/mainweb/saveMemberHarvestAcceptance/" + year ;
}

